const dateFormatter = (
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  eventDate = new Date()
) => {
  const formatter = Intl.DateTimeFormat('en-US', {
    timeZone,
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  });
  return formatter.format(eventDate);
};

const useDateFormatter = () => {
  return {
    dateFormatter,
  };
};

export default useDateFormatter;
